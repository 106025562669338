<template>
  <v-card id="cw-confirmation-dialog--dialog__indentification-interrupted">
    <v-card-title id="cw-confirmation-dialog__title" class="text-h5">
      {{ message }}
    </v-card-title>
    <v-card-text id="cw-confirmation-dialog__text">
      <p v-translate>
        To proceed application you must authenticate yourself.
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        id="cw-confirmation-dialog__confirm"
        :loading="$wait.waiting('confirmation_dialog__confirm_processing')"
        :disabled="$wait.waiting('confirmation_dialog__confirm_processing')"
        color="primary"
        text
        @click="
          $eventLogger.clickEvent($event);
          closeDialog();
        "
      >
        <translate>
          Close
        </translate>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { exceptions } from '@shared/config';

export default {
  name: 'CwDialogIdentificationInterrupted',

  computed: {
    ...mapState({
      errorCode: state => state.identification.errorCode,
    }),

    message() {
      if (!this.errorCode) return '';

      return exceptions[this.errorCode].message;
    },
  },

  methods: {
    ...mapActions({
      removeException: 'removeException',
    }),

    closeDialog() {
      this.removeException('IDENTIFICATION__INTERRUPTED');
      this.$emit('dialog-cancel');
    },
  },
};
</script>
